(function ($) {
  $(window).on('pageshow', function () {
    // Anchor position after the page load
    var subSectionHashNoslash = window.location.hash;
    // Accounting for slash added by shade router
    var subSectionHash = decodeURIComponent(subSectionHashNoslash.toString().split('/').join('_'));
    var subSectionNospace = subSectionHash?.replace(/[' '(/]/g, '')?.split('?')[0];
    var $anchorElement = $([subSectionNospace][0], document);
    var fixedBarHeight;

    if (subSectionNospace && $anchorElement.length > 0) {
      fixedBarHeight = $('.js-site-header-sticky', document).height();

      if (!isNaN(fixedBarHeight)) {
        $('html, body').animate({
          scrollTop: $anchorElement.offset().top - fixedBarHeight
        }, 1000);
      }
    }
  });
})(jQuery);
